import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import { Circle } from "@styled-icons/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DynamicDelayWarning = ({ delays }) => {
  const [open, setOpen] = useState(true);
  const [index, setIndex] = useState(0);

  return (
    <Dialog
      maxWidth="sm"
      disableEscapeKeyDown
      disableBackdropClick
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          boxShadow: "none",
        },
      }}
    >
      <DialogContent>
        <div key={delays[index].title} style={{ textAlign: "center" }}>
          <img
            src={delays[index].image}
            alt={delays[index].title}
            width="100%"
          />
          <h2>{delays[index].title}</h2>
          <p>{delays[index].body}</p>

          {delays.length > 1 && (
            <div
              style={{
                marginTop: 20,
              }}
            >
              {delays.map((delay, i) => (
                <Circle
                  key={i}
                  size={i === index ? 22 : 20}
                  color={i === index ? "green" : "grey"}
                  style={{ margin: 2 }}
                />
              ))}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {delays.length > 1 && index > 0 && (
          <Button
            onClick={() => setIndex(index - 1)}
            variant="contained"
            style={{
              color: "#ffff",
              backgroundColor: "#000",
              boxShadow: "none",
              borderRadius: 2,
              marginTop: 50,
              paddingTop: 10,
              fontWeight: "bold",
            }}
          >
            BACK
          </Button>
        )}
        <Button
          onClick={() => {
            if (delays.length === index + 1) {
              setOpen(false);
            } else {
              setIndex(index + 1);
            }
          }}
          variant="contained"
          style={{
            color: "#ffff",
            backgroundColor: "#d9534f",
            boxShadow: "none",
            borderRadius: 2,
            marginTop: 50,
            paddingTop: 10,
            fontWeight: "bold",
          }}
        >
          {delays.length === index + 1 ? "DONE" : "NEXT"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicDelayWarning;
