import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Grid,
} from "@material-ui/core/";
import Ionicon from "react-ionicons";
import "../App.css";
import Cart from "./cart";

import Customer from "./checkout.customer";
import Payment from "./checkout.payment";
import DynamicDelayWarning from "./dynamic.delay.warning";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CheckoutDialog = (props) => {
  const [hideBackBtn, setHideBackBtn] = useState(false);
  const [cart, setCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [times, setTimes] = useState([]);
  const [customer_details, setCustomer] = useState({});

  useEffect(() => {
    if (props.user) {
      const { kra_pin: krap } = props.user;

      if (krap) {
        setCustomer({
          kra_pin: krap,
        });
      }
    }
  }, [props.user]);

  return (
    <Dialog
      maxWidth="md"
      fullScreen
      open={props.open}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ backgroundColor: "#f4f4f4", padding: 0 }}>
        <div
          style={{
            display: "inline-flex",
            paddingLeft: 100,
            marginTop: 10,
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          <Typography
            style={{
              backgroundColor: "#d71920",
              marginRight: 10,
              paddingRight: 5,
              paddingLeft: 5,
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={hideBackBtn ? null : props.close}
          >
            <Ionicon
              style={{
                backgroundColor: "#d71920",
                fill: "#ffff",
                width: 25,
                height: 25,
                zIndex: 1,
                marginBottom: -7,
              }}
              icon="ios-arrow-back"
            />
            <span style={{ marginLeft: 5 }}>EDIT</span>
          </Typography>
          <Typography
            onClick={hideBackBtn ? null : props.abandon}
            style={{
              backgroundColor: "#808080",
              paddingRight: 5,
              paddingLeft: 5,
              cursor: "pointer",
              color: "#fff",
            }}
          >
            ABANDON ORDER
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "#f4f4f4",
          paddingLeft: 100,
          paddingRight: 100,
        }}
      >
        <Grid container>
          <Grid
            item
            sm={4}
            style={{
              marginRight: 40,
              maxWidth: "30%",
            }}
          >
            <Grid container>
              <Grid
                item
                sm={1}
                style={{ marginBottom: 10, fontWeight: "bold" }}
              >
                <Typography
                  align="center"
                  variant="body1"
                  style={{ backgroundColor: "#D71920", color: "#ffff" }}
                >
                  1
                </Typography>
              </Grid>
              <Grid
                item
                sm={9}
                style={{ marginBottom: 10, fontWeight: "bold", marginLeft: 10 }}
              >
                <Typography align="left" variant="body1">
                  CART
                </Typography>
              </Grid>
            </Grid>

            <Cart
              checkout={true}
              version={props.version}
              cart={props.cart}
              currency={props.currency}
              orderUUID={props.orderUUID}
              confirm={() => setCart(true)}
              collectionTimes={(times) => setTimes(times)}
              tax={props.tax}
              setMyTotals={props.setMyTotals}
              myTotals={props.myTotals}
              instruction={props.instruction}
              updateCart={props.updateCart}
              setLoading={(loading) => setLoading(loading)}
              availableCurrency={props.availableCurrency}
              phone={props.phone}
            />
          </Grid>
          <Grid
            item
            sm={4}
            style={{
              marginRight: 40,
              maxWidth: "30%",
            }}
          >
            <Grid container>
              <Grid
                item
                sm={1}
                style={{ marginBottom: 10, fontWeight: "bold" }}
              >
                <Typography
                  align="center"
                  variant="body1"
                  style={{ backgroundColor: "#D71920", color: "#ffff" }}
                >
                  2
                </Typography>
              </Grid>
              <Grid
                item
                sm={9}
                style={{ marginBottom: 10, fontWeight: "bold", marginLeft: 5 }}
              >
                <Typography align="left" variant="body1">
                  ORDER DETAILS
                </Typography>
              </Grid>
            </Grid>

            <Customer
              addCustomer={props.addCustomer}
              addCardDetails={props.addCardDetails}
              orderUUID={props.orderUUID}
              confirmOrder={props.confirmOrder}
              client={props.customer}
              callerId={props.callerId}
              getAmount={props.getAmount}
              kraPin={props.orderResponse.kra_pin}
              user={props.user}
              cart={cart}
              edit={(value) => {
                setCustomer((prevState) => {
                  return { ...prevState, ...value };
                });
              }}
              classes={props.classes}
              collectionTimes={times}
            />
          </Grid>
          <Grid item sm={4} style={{ maxWidth: "30%" }}>
            <Grid container>
              <Grid
                item
                sm={1}
                style={{ marginBottom: 10, fontWeight: "bold" }}
              >
                <Typography
                  align="center"
                  variant="body1"
                  style={{ backgroundColor: "#D71920", color: "#ffff" }}
                >
                  3
                </Typography>
              </Grid>
              <Grid
                item
                sm={9}
                style={{ marginBottom: 10, fontWeight: "bold", marginLeft: 10 }}
              >
                <Typography align="left" variant="body1">
                  PAYMENT
                </Typography>
              </Grid>
            </Grid>
            <main
              style={{
                border: " thin solid #808080",
                overflowY: "scroll",
              }}
            >
              <Payment
                callerId={props.callerId}
                complex={props.complex}
                phone={props.phone}
                customer_details={customer_details}
                payments={props.payments}
                orderUUID={props.orderUUID}
                setShowFeedback={props.setShowFeedback}
                setFeedback={props.setFeedback}
                country={props.country}
                currency={props.currency}
                amount={props.amount}
                cart={props.cart}
                customer={props.customer}
                hideBackBtn={(result) => {
                  setHideBackBtn(result);
                }}
                complete={(result, order) => {
                  props.complete(result, order);
                }}
                enablePayment={
                  props.proceed && !props.cartLoading && !loading ? true : false
                }
              />
            </main>
          </Grid>
        </Grid>

        {Boolean(props.orderResponse.delays?.length) && (
          <DynamicDelayWarning delays={props.orderResponse.delays} />
        )}

        <div className="version-checkout-button">{props.version}</div>
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutDialog;
